// Sample Axio Instance
import axios from 'axios'
import store from '@/store'
import router from '@/router/route.js'
import i18n from '@/locales/i18n.js'

const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API + '/api/client',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

client.interceptors.request.use(function (config) {
  const token = store.getters['auth/token']
  const userId = store.getters['auth/userId']
  const language = store.getters['lang/locale'] ?? 'mm'

  config.headers.common['Authorization'] = `Bearer ${token}`
  config.headers.common['x-user-id'] = userId
  config.headers.common['x-language'] = language

  return config
})

client.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          return Promise.reject(err.response.data.response.message)
        case 403:
          store.commit('auth/LOGOUT')
          router.push({ name: 'login' })
          return Promise.reject(err.response.data.response.message)
        case 404:
          return Promise.reject(i18n.t('not_found'))
        case 500:
          return Promise.reject(i18n.t('server_error'))
        default:
          return Promise.reject(i18n.t('something_wrong'))
      }
    } else if (err.request) {
      return Promise.reject(i18n.t('network_error'))
    } else {
      return Promise.reject(i18n.t('something_wrong'))
    }
  }
)
export default client
